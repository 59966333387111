<template>
  <actions-button class="block lg:hidden" size="sm" variant="secondary" @click="showExtraMenus">
    <icon name="mdi:hamburger"/>
  </actions-button>

  <div v-if="displayExtraMenus" class="block lg:hidden fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-40"
       @click="hideExtraMenus"></div>

  <div v-if="displayExtraMenus"
       class="block lg:hidden fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform bg-white w-64">
    <div class="py-4 overflow-y-auto">
      <feedback-note title="Save time and increase engagement" :bold="true"/>

      <ul class="space-y-2">
        <li v-for="extraMenuItem in EXTRA_MENU_ITEMS">
          <nuxt-link :to="extraMenuItem.href"
                     class="flex items-center space-x-2 py-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                     @click="hideExtraMenus">
            <icon :name="extraMenuItem.icon" size="24"/>

            <span>
              {{ extraMenuItem.label }}
            </span>
          </nuxt-link>
        </li>
      </ul>
    </div>

    <div class="py-4 overflow-y-auto">
      <div class="text-xs font-semibold text-gray-600 mb-2">
        Management
      </div>

      <ul class="space-y-2">
        <li v-for="extraMenuItem in AVATAR_MENU_ITEMS">
          <nuxt-link :to="extraMenuItem.href"
                     class="flex items-center space-x-2 py-2 text-gray-900 rounded-lg hover:bg-gray-100 group"
                     @click="hideExtraMenus">
            <icon :name="extraMenuItem.icon" size="24"/>

            <span>
              {{ extraMenuItem.label }}
            </span>
          </nuxt-link>
        </li>
      </ul>
    </div>

    <t-divider-horizontal/>

    <actions-logout class="py-3"/>
  </div>
</template>

<script setup>
import {
  ACCOUNT,
  BILLING,
  HASHTAG_LISTS,
  RSS_FEED,
  SECURITY,
  SIGNATURES,
  TEMPLATES,
  WATERMARKS,
  WORKSPACE
} from '~/helpers/navigation-items.js'

const EXTRA_MENU_ITEMS = ref([RSS_FEED, WATERMARKS, TEMPLATES, SIGNATURES, HASHTAG_LISTS])
const AVATAR_MENU_ITEMS = [WORKSPACE, ACCOUNT, SECURITY, BILLING]

const route = useRoute()
const displayExtraMenus = ref(false)

function showExtraMenus() {
  displayExtraMenus.value = true

  document.body.classList.add('overflow-hidden')

  window.addEventListener('keydown', close)
}

function hideExtraMenus() {
  displayExtraMenus.value = false

  document.body.classList.remove('overflow-hidden')

  window.removeEventListener('keydown', close)
}

function close(event) {
  if (event.key === 'Escape') {
    hideExtraMenus()
  }
}

watch(() => route.path, () => hideExtraMenus())
</script>
