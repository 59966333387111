<template>
  <div
      class="sticky top-0 z-10 flex w-full h-16 shrink-0 items-center gap-x-4 px-4 sm:gap-x-6 sm:px-6 lg:px-8 bg-white border-b">
    <navigation-menus-mobile-sidebar/>

    <div class="flex flex-1 gap-x-4 justify-end lg:justify-between lg:gap-x-6">
      <div class="hidden lg:flex items-center">
<!--        <p class="label-sm">
          <a href="#">
            Join us in Denver from June 7 – 9 to see what’s coming next&nbsp;<span aria-hidden="true">&rarr;</span>
          </a>
        </p>-->
      </div>

      <div class="flex items-center gap-x-4 lg:gap-x-6">
        <navigation-menus-workspaces v-if="user.plan.id"/>

        <nuxt-link v-else class="btn-primary-sm" to="/settings/billing">
          Get a paid plan for free
        </nuxt-link>

<!--        <navigation-notifications-list class="hidden lg:block"/>-->

        <t-divider-vertical class="hidden lg:block"/>

        <navigation-menus-avatar class="hidden lg:block"/>

        <t-divider-vertical class="hidden lg:block"/>

        <navigation-menus-help class="hidden lg:block"/>
      </div>
    </div>
  </div>
</template>

<script setup>
const user = computed(() => authStore().user)
</script>
