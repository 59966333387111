<template xmlns="http://www.w3.org/1999/html">
  <div
      class="absolute right-0 z-50 mt-2.5 w-48 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none select-none text-gray-700 text-sm">
    <template v-for="(menuItem, index) in menuItems">
      <div v-if="index === 0 || menuItem.group !== menuItems[index - 1].group">
        <p class="px-5 text-xs text-gray-500 mt-2 uppercase tracking-widest">
          {{ menuItem.group }}
        </p>
      </div>

      <a
          v-if="isAbsoluteUrl(menuItem.href)"
          :href="menuItem.href"
          :class="{
            'bg-gray-50': $route.path === menuItem.href,
            'text-indigo-600 font-bold': menuItem.label === 'Billing',
          }"
          class="flex items-center space-x-2 px-5 py-2.5 hover:bg-gray-50"
          target="_blank"
          rel="noopener noreferrer">
        <icon v-if="menuItem.icon" :name="menuItem.icon" size="18"/>
        <span class="w-full">{{ menuItem.label }}</span>
      </a>

      <nuxt-link
          v-else
          :class="{
            'bg-gray-50': $route.path === menuItem.href,
            'text-indigo-600 font-bold': menuItem.label === 'Billing',
          }"
          :to="menuItem.href"
          class="flex items-center space-x-2 px-5 py-2.5 hover:bg-gray-50">
        <icon v-if="menuItem.icon" :name="menuItem.icon" size="18"/>
        <span class="w-full">
          {{ menuItem.label }}
        </span>
      </nuxt-link>

      <t-divider-horizontal
          v-if="index < menuItems.length - 1 && menuItem.group !== menuItems[index + 1].group"/>
    </template>

    <t-divider-horizontal v-if="$slots.append_menu_items"/>

    <slot name="append_menu_items"/>
  </div>
</template>

<script setup>
defineProps({
  menuItems: {
    type: Array,
    required: true,
  },
})

const isAbsoluteUrl = (url) => {
  try {
    new URL(url)
    return true
  } catch {
    return false
  }
}
</script>