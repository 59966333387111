<template>
  <div v-click-outside="hideDropdownMenu" class="relative">
    <button class="flex items-center space-x-1.5 text-sm text-gray-500 cursor-pointer select-none"
            @click="showDropdownMenu = !showDropdownMenu">
      <span>
        Help
      </span>

      <icon name="material-symbols:help-outline-rounded" size="20"/>
    </button>

    <inputs-drop-down-navigation v-if="showDropdownMenu" :menu-items="DROPDOWN_MENU_ITEMS"/>
  </div>
</template>

<script setup>
const route = useRoute()

const DROPDOWN_MENU_ITEMS = [
  {
    label: 'Learn',
    icon: 'material-symbols:video-library-outline-rounded',
    href: 'https://www.youtube.com/@ScreenCastsDev',
    group: 'resources',
  },

  {
    label: 'What\'s new?',
    icon: 'material-symbols:info-outline-rounded',
    href: 'https://blog.thelisting.app/whats-new',
    group: 'resources',
  },

  {
    label: 'Blog',
    icon: 'material-symbols:article-outline-rounded',
    href: 'https://blog.thelisting.app',
    group: 'resources',
  },

  {
    label: 'Roadmap',
    icon: 'material-symbols:map-outline-rounded',
    href: 'https://roadmap.thelisting.app',
    group: 'feedback',
  },

  {
    label: 'Suggest an idea',
    icon: 'material-symbols:lightbulb-outline-rounded',
    href: 'https://roadmap.thelisting.app/1-your-ideas-our-work/tabs/1-under-consideration/submit-idea',
    group: 'feedback',
  },

  {
    label: 'Help center',
    icon: 'material-symbols:help-outline-rounded',
    href: 'https://help.thelisting.app',
    group: 'problems & issues',
  },

  {
    label: 'Report a problem',
    icon: 'material-symbols:bug-report-outline-rounded',
    href: 'https://help.thelisting.app/report-a-problem',
    group: 'problems & issues',
  },
]

const showDropdownMenu = ref(false)

function hideDropdownMenu() {
  showDropdownMenu.value = false
}

watch(() => route.path, () => hideDropdownMenu())
</script>